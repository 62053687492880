export enum VesStatusEnum {
  UNSUITED = 'unsuited',
  SUITED = 'suited',
  INTERESTED = 'interested',
  PARTICIPATING = 'participating',
  INFORMED = 'informed',
  UNINTERESTED = 'uninterested',
  EXCLUDED = 'excluded'
}

export const VesStatusLabels: {[key: string] :string} = {
  [VesStatusEnum.UNSUITED]: 'Niet geschikt',
  [VesStatusEnum.SUITED]: 'Geschikt',
  [VesStatusEnum.INTERESTED]: 'Geïnteresseerd',
  [VesStatusEnum.PARTICIPATING]: 'Deelname',
  [VesStatusEnum.INFORMED]: 'Geïnformeerd',
  [VesStatusEnum.UNINTERESTED]: 'VES geweigerd',
  [VesStatusEnum.EXCLUDED]: 'VES uitgesloten',
};
