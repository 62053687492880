import Echo from 'laravel-echo';
import { Token } from '@/support/Token';
import { User } from '@/models/User';
import { identifyHostname } from '@/support/Client';
import { Application } from '@/support/Application';

export class PusherService {
  private $author: User | null = null;

  private $echo: any;

  private $room: any;

  public author(author: User) {
    this.$author = author;
    return this;
  }

  public room(room: string) {
    this.$room = room;
    return this;
  }

  public unsubscribe(room: string) {
    if (this.$echo) {
      this.$echo.leave(room);
    }
  }

  public echo() {
    (window as any).Pusher = require('pusher-js');
    (window as any).Pusher.logToConsole = Application.config('APP_ENV') !== 'production';

    const pusherKey: string = Application.config('APP_PUSHER_KEY') !== undefined ? Application.config('APP_PUSHER_KEY') as string : '';
    return this.$echo = new Echo({
      broadcaster: 'pusher',
      key: pusherKey,
      authEndpoint: `${identifyHostname()}/v1/broadcasting/auth`,
      auth: {
        headers: {
          Authorization: `Bearer ${Token.get('access')}`,
          Accept: 'application/json',
        },
      },
      namespace: '',
      cluster: Application.config('APP_PUSHER_CLUSTER') || 'eu',
      encrypted: Application.config('APP_PUSHER_ENCRYPTED') === 'true' || Application.config('APP_PUSHER_ENCRYPTION') === '1',
    }).private(`${this.$room}`);
  }
}
